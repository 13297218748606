<div class="toolbar">
    <div class="logo-sign-container">
        <a [routerLink]="['/']">
            <img class="logo-sign" src="assets/images/emmeleiaLogoTransparent.png">
        </a>
    </div>
    <div class="options-container font-byzantine">
        <a [routerLink]="['/']">Αρχικη</a>
        <a [routerLink]="['about']">Σχετικα</a>
        <a [routerLink]="['contact']">Επικοινωνια</a>
    </div>
</div>
<app-products-toolbar></app-products-toolbar>