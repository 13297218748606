
export class Svg {
    static readonly flower: ISvg = {url: 'assets/images/slideShow/Flower_Line.svg', name: 'flowerLine'};
    static readonly store: ISvg = {url: 'assets/images/footer/store.svg', name: 'store'};
    static readonly phone: ISvg = {url: 'assets/images/footer/phone.svg', name: 'phone'};
    static readonly facebook: ISvg = {url: 'assets/images/footer/facebook.svg', name: 'facebook'};
    static readonly email: ISvg = {url: 'assets/images/footer/envelope.svg', name: 'email'};
    static readonly clock: ISvg = {url: 'assets/images/footer/clock.svg', name: 'clock'};
}

export interface ISvg{
    url: string;
    name: string;
}
