import { Component, OnInit } from '@angular/core';
import { PRODUCTS_ITEMS_TOOLBAR } from 'src/app/shared/constants/products-categories.constant';
import { ProductCategoryModel } from 'src/app/shared/models/product-category.model';

@Component({
  selector: 'app-products-toolbar',
  templateUrl: './products-toolbar.component.html',
  styleUrls: ['./products-toolbar.component.scss']
})
export class ProductsToolbarComponent implements OnInit {

    products: ProductCategoryModel[] = PRODUCTS_ITEMS_TOOLBAR;
    constructor() { }

    ngOnInit(): void {
    }

}
