import { Component, OnInit } from '@angular/core';
import { Svg } from 'src/app/shared/models/svg';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    constructor() { }
    svg = Svg;

    ngOnInit(): void {
    }

}
