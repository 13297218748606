// tslint:disable:no-non-null-assertion

import { ProductCategoryModel } from '../models/product-category.model';
import { SwiperSlideShowModel } from '../models/swiper-slide.model';


export const PRODUCTS_ITEMS_TOOLBAR: ProductCategoryModel[] = [
        {name: 'Βιβλια', id: 'books'},
        {name: 'Εικονες', id: 'icons'},
        {name: 'Εκκλησιαστικα', id: 'church-items'},
        {name: 'Βιολογικα', id: 'bio-items'},
        {name: 'Δωρα', id: 'gifts'},
        {name: 'Μπομπονιερες', id: 'mpomponieres'}
    ];

export const PRODUCTS_CATEGORIES: ProductCategoryModel[] = [
    {name: 'Βιβλία', id: 'books'},
    {name: 'Εικόνες', id: 'icons'},
    {name: 'Εκκλησιαστικά', id: 'church-items'},
    {name: 'Βιολογικά', id: 'bio-items'},
    {name: 'Δώρα', id: 'gifts'},
    {name: 'Μπομπονιέρες', id: 'mpomponieres'}
];

export const PRODUCTS_SUBCATEGORIES = new Map<string, ProductCategoryModel[]>([
    ['books', [{ name: 'Βίοι Αγίων',            id: 'saint-life'},
               { name: 'Συγγράμματα Αγίων',     id: 'saint-writing'},
               { name: 'Πατερικά Κείμενα',      id: 'fathers-writing'},
               { name: 'Ερμηνευτικά',           id: 'interpretive'},
               { name: 'Ασκητικά',              id: 'ascetics'},
               { name: 'Λειτουργικά',           id: 'services'},
               { name: 'Προσευχητάρια',         id: 'prayer-books'},
               { name: 'Ψυχωφελή',              id: 'soulful'},
               { name: 'Διηγήματα',             id: 'short-stories'},
               { name: 'Εφηβικά',               id: 'teenage'},
               { name: 'Παιδικά',               id: 'child'},
               { name: 'Βυζαντινής Μουσικής',   id: 'byzantine-music'},
               { name: 'Αντιαιρετικά',          id: 'anti-sectarian'},
               { name: 'Ιστορικά',              id: 'historical'}
            ]
    ],
    // total of 5
    ['icons', [ {name: 'Σε Ξύλο',           id: 'wooden'},
                {name: 'Σε Γυαλί',          id: 'glass'},
                {name: 'Χειροποίητες',      id: 'handmade'},
                {name: 'Μαγνήτη',           id: 'magnetic'},
                {name: 'Μικρές Διάφορες',   id: 'small-other'},
                {name: 'Μεγάλες Διάφορες',  id: 'big-other'}
            ]
    ],
    // total of 4
    ['church-items', [ {name: 'Καντήλια',    id: 'oil-lamps'},
                       {name: 'Καπνιστήρια', id: 'thurible'},
                       {name: 'Θυμίαμα',     id: 'incense'},
                       {name: 'Λουμίνια',    id: 'wick'},
                       {name: 'Καρβουνάκια', id: 'coal'},
                       {name: 'Νάμα',        id: 'nama'},
                       {name: 'Κεριά',       id: 'candles'}
                    ]
    ],
    // total of 5
    ['bio-items', [ {name: 'Μπαχαρικά',             id: 'spices'},
                    {name: 'Βότανα',                id: 'herbs'},
                    {name: 'Κρασί',                 id: 'wine'},
                    {name: 'Αποστάγματα',           id: 'liquids'},
                    {name: 'Μέλι',                  id: 'honey'},
                    {name: 'Αρωματικά Κεριά',       id: 'scented-candles'},
                    {name: 'Θεραπευτικές Κρέμες',   id: 'creams'},
                    {name: 'Σαπούνια',              id: 'soap'},

                ]
    ],
    // total of 4
    ['gifts', [ {name: 'Σταυροί',           id: 'holy-cross'},
                {name: 'Κομποσχοίνια',      id: 'bracelets'},
                {name: 'Μπρελόκ',           id: 'key-chains'},
                {name: 'Ψηφιδωτά',          id: 'mosaics'},
                {name: 'Καλάθια Δώρων',      id: 'gift-baskets'}
            ]
    ],
    ['mpomponieres', [{name: 'Όλες',           id: 'all'}]]
]);


export const BOOKS_SWIPER: SwiperSlideShowModel[] = [
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![0],
        imgSrc: 'assets/images/multiSlideshow/books/saintLife.jpeg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![1],
        imgSrc: 'assets/images/multiSlideshow/books/saintwriting.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![2],
        imgSrc: 'assets/images/multiSlideshow/books/fathersWriting.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![3],
        imgSrc: 'assets/images/multiSlideshow/books/interpretive.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![4],
        imgSrc: 'assets/images/multiSlideshow/books/ascetics.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![5],
        imgSrc: 'assets/images/multiSlideshow/books/services.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![6],
        imgSrc: 'assets/images/multiSlideshow/books/prayerbook.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![7]!,
        imgSrc: 'assets/images/multiSlideshow/books/soulful.png'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![8],
        imgSrc: 'assets/images/multiSlideshow/books/short-stories.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![9],
        imgSrc: 'assets/images/multiSlideshow/books/teenage.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![10],
        imgSrc: 'assets/images/multiSlideshow/books/children.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![11],
        imgSrc: 'assets/images/multiSlideshow/books/byzantineMusic.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![12],
        imgSrc: 'assets/images/multiSlideshow/books/antisectarian.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('books')![13],
        imgSrc: 'assets/images/multiSlideshow/books/historical.jpg'
    }
];

export const ICONS_SWIPER: SwiperSlideShowModel[] = [
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('icons')![0],
        imgSrc: 'assets/images/multiSlideshow/Icons/woodenIcons.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('icons')![1],
        imgSrc: 'assets/images/multiSlideshow/Icons/glassIcons.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('icons')![2],
        imgSrc: 'assets/images/multiSlideshow/Icons/iconshandmade.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('icons')![3],
        imgSrc: 'assets/images/multiSlideshow/Icons/magneticIcons.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('icons')![4],
        imgSrc: 'assets/images/multiSlideshow/Icons/smallOther.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('icons')![5],
        imgSrc: 'assets/images/multiSlideshow/Icons/bigOther.jpg'
    }
];

export const CHURCH_ITEMS_SWIPER: SwiperSlideShowModel[] = [
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('church-items')![0],
        imgSrc: 'assets/images/multiSlideshow/churchItems/oillamps.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('church-items')![1],
        imgSrc: 'assets/images/multiSlideshow/churchItems/thurible.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('church-items')![2],
        imgSrc: 'assets/images/multiSlideshow/churchItems/incense.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('church-items')![3],
        imgSrc: 'assets/images/multiSlideshow/churchItems/wick.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('church-items')![4],
        imgSrc: 'assets/images/multiSlideshow/churchItems/coal.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('church-items')![5],
        imgSrc: 'assets/images/multiSlideshow/churchItems/nama.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('church-items')![6],
        imgSrc: 'assets/images/multiSlideshow/churchItems/candles.jpg'
    }
];


export const BIO_ITEMS_SWIPER: SwiperSlideShowModel[] = [
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('bio-items')![0],
        imgSrc: 'assets/images/multiSlideshow/bioItems/spices.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('bio-items')![1],
        imgSrc: 'assets/images/multiSlideshow/bioItems/herbs.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('bio-items')![2],
        imgSrc: 'assets/images/multiSlideshow/bioItems/wine.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('bio-items')![3],
        imgSrc: 'assets/images/multiSlideshow/bioItems/liquids.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('bio-items')![4],
        imgSrc: 'assets/images/multiSlideshow/bioItems/honey.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('bio-items')![5],
        imgSrc: 'assets/images/multiSlideshow/bioItems/scentedCandles.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('bio-items')![6],
        imgSrc: 'assets/images/multiSlideshow/bioItems/creams.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('bio-items')![7],
        imgSrc: 'assets/images/multiSlideshow/bioItems/soap.jpg'
    }
];


export const GIFTS_SWIPER: SwiperSlideShowModel[] = [
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('gifts')![0],
        imgSrc: 'assets/images/multiSlideshow/gifts/holycross.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('gifts')![1],
        imgSrc: 'assets/images/multiSlideshow/gifts/bracelets.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('gifts')![2],
        imgSrc: 'assets/images/multiSlideshow/gifts/keyChain.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('gifts')![3],
        imgSrc: 'assets/images/multiSlideshow/gifts/mosaics.jpg'
    },
    {
        productCategory: PRODUCTS_SUBCATEGORIES.get('gifts')![4],
        imgSrc: 'assets/images/multiSlideshow/gifts/giftbasket.jpg'
    }
];
