<div class="container">
    <div class="content-container">
        <div class="image-container">
            <img src="assets/images/AgiaEmmeleiaIcon.jpeg" class="image image-footer">
        </div>
        <div class="contact-container sec-color">
            <div class="caption-container">
                <h3 class="font-byzantine">Χριστιανικo Βιβλιοπωλειo</h3>
                <h2 class="font-byzantine">ΕΜΜΕΛΕΙΑ</h2>
            </div>
            <div class="contact-info">
                <div class="contact-icon-info">
                    <div class="icon">
                        <mat-icon inline [svgIcon]="svg.clock.name" class="svg-icon"></mat-icon>
                    </div>
                    <div>
                        <p>Δευ - Σαβ</p>
                        <p>09:00 - 13:00</p>
                        <p class="space-top">Κυριακή</p>
                        <p>Κλειστό</p>
                    </div>
                </div>
                <div class="address contact-icon-info">
                    <div class="icon">
                        <mat-icon inline [svgIcon]="svg.store.name" class="svg-icon"></mat-icon>
                    </div>
                    <div>
                        <p>Αχιλλέως 2</p>
                        <p>Παραλίμνι</p>
                        <p>Τ:K: 5281</p>
                        <p>Αμμόχωστος</p>
                        <p>Κύπρος</p>
                    </div>
                </div>
                <div class="media">
                    <div class="contact-icon-info">
                        <div class="icon">
                            <mat-icon inline [svgIcon]="svg.phone.name" class="svg-icon"></mat-icon>
                        </div>
                        <div>
                            <a class="sec-color" href="tel:+35723828315">23828315</a>
                        </div>
                    </div>
                    <div class="contact-icon-info">
                        <div class="icon">
                            <mat-icon inline [svgIcon]="svg.email.name" class="svg-icon"></mat-icon>
                        </div>
                        <div>
                            <a class="sec-color" href="mailto:emmeleiabookshop@gmail.com">emmeleiabookshop@gmail.com</a>
                        </div>
                    </div>
                    <div class="contact-icon-info">
                        <div class="icon">
                            <mat-icon inline [svgIcon]="svg.facebook.name" class="svg-icon"></mat-icon>
                        </div>
                        <div>
                            <a class="sec-color" href="https://www.facebook.com/Βιβλιοπωλείο-Εμμέλεια-101026845299779/">Βιβλιοπωλείο Εμμέλεια</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </div>
</div>