import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: '',
        loadChildren: () =>
            import('./modules/home/home.module')
            .then(m => m.HomeModule)
    },
    { path: 'contact',
        loadChildren: () =>
            import('./modules/contact/contact.module')
            .then(m => m.ContactModule)
    },
    { path: 'not-found',
        loadChildren: () =>
            import('./modules/not-found/not-found.module')
            .then(m => m.NotFoundModule)
    },
    { path: 'about',
        loadChildren: () =>
            import('./modules/about/about.module')
            .then(m => m.AboutModule)
    },
    { path: '**',
        loadChildren: () =>
            import('./modules/not-found/not-found.module')
            .then(m => m.NotFoundModule)
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
