import { Component, OnInit } from '@angular/core';
import { ISvg, Svg } from './shared/models/svg';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
    title = 'emmeleia';

    constructor(private _matIconRegistry: MatIconRegistry,
                private _domSanitizer: DomSanitizer){}

    ngOnInit(): void {
        Object.keys(Svg).forEach(key => {
            // Register all icons
            const svg: ISvg = Reflect.get(Svg, key) as ISvg;
            this._matIconRegistry.addSvgIcon(
                svg.name,
                this._domSanitizer.bypassSecurityTrustResourceUrl(svg.url)
            );
        });
    }
}
